import { RouteRecordRaw } from 'vue-router'

import { roleAdminOrBackOffice, roleAdmin } from 'src/api/MenuItems'

const routes: RouteRecordRaw[] = [
  {
    path: '/public',
    component: () => import('layouts/PublicLayout.vue'),
    children: [{ name: 'Waste-Transfer-Form', path: 'waste-transfer-form', component: () => import('pages/public-forms/WasteTransferForm.vue') }]
  },
  {
    path: '/auth',
    component: () => import('layouts/PublicLayout.vue'),
    children: [{ name: 'Login', path: 'login', component: () => import('pages/auth/Login.vue'), meta: { requiresGuest: true } }]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Index.vue'), name: 'Dashboard', meta: { requiresLogin: true } },
      { path: '/employee-management', component: () => import('pages/masters/EmployeeManagement.vue'), name: 'Employee-Management', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: '/employee-leave-management', component: () => import('pages/masters/EmployeeLeaveManagement.vue'), name: 'Employee-Leave-Management', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: '/pscl-question-management', component: () => import('pages/masters/PSCLQuestionManagement.vue'), name: 'PSCL-Question-Management', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: '/client-management', component: () => import('pages/masters/ClientManagement.vue'), name: 'Client-Management', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: '/job-management', component: () => import('pages/masters/JobManagement.vue'), name: 'Job-Management', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: '/document-management', component: () => import('pages/masters/DocumentManagement.vue'), name: 'Document-Management', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: '/planning-sheet', component: () => import('pages/planning/PlanningSheet.vue'), name: 'Planning-Sheet', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: '/fatigue-assessment', component: () => import('pages/planning/FatigueAssessment.vue'), name: 'Fatigue-Assessment', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: '/fatigue-assessment/:forDate', component: () => import('pages/planning/FatigueAssessment.vue'), name: 'Fatigue-Assessment-For-Date', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      {
        path: '/user-management',
        component: () => import('pages/masters/UserManagement.vue'),
        name: 'User-Management',
        meta: { requiresAnyOfRoles: roleAdmin }
      },
      {
        path: '/public-form-submissions',
        component: () => import('pages/public-form-submissions/Index.vue'),
        name: 'Public-FormSubmissions',
        meta: { requiresAnyOfRoles: roleAdminOrBackOffice }
      }
    ]
  },
  {
    path: '/timesheet',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/timesheet/Index.vue'), name: 'Timesheet', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: 'week-starting/:weekStartingDateString', component: () => import('pages/timesheet/Index.vue'), name: 'Timesheet by Week', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } },
      { path: 'submission/:employeeId/:weekStartingDateString', component: () => import('pages/timesheet/Index.vue'), name: 'Timesheet by Employee and Week', meta: { requiresLogin: true } }
    ]
  },
  {
    path: '/fill-timesheet',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Index.vue'), name: 'Timesheet fill for employee', meta: { requiresLogin: true } },
      { path: 'submission/:employeeId', component: () => import('pages/timesheet/Index.vue'), name: 'Timesheet by Employee', meta: { requiresLogin: true } }
    ]
  },
  {
    path: '/reports',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/reports/Index.vue'), name: 'Reports Dashboard', meta: { requiresAnyOfRoles: roleAdminOrBackOffice } }
      // { path: 'fatigue/fatigue-zone', component: () => import('pages/reports/fatigue/FatigueZone.vue'), name: 'Fatigue Zone', meta: { requiresLogin: true } }
      // { path: 'fatigue/fatigue-index', component: () => import('pages/reports/fatigue/FatigueIndex.vue'), name: 'Fatigue Index', meta: { requiresLogin: true } }
    ]
  },
  {
    path: '/shift-report',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: 'fill/:planningSheetId', component: () => import('pages/planning/ShiftReportFill.vue'), name: 'Shift-Report-Fill', meta: { requiresLogin: true } }

    ]
  },
  {
    path: '/configuration',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/masters/Configuration.vue'), name: 'Configuration', meta: { requiresAnyOfRoles: roleAdmin } }
      // { path: 'trade-type-management', component: () => import('pages/masters/TradeTypeManagement.vue'), name: 'Trade-Type-Management', meta: { requiresAnyOfRoles: ['Administrator'] } },
      // { path: 'job-title-management', component: () => import('pages/masters/JobTitleManagement.vue'), name: 'Job-Title-Management', meta: { requiresAnyOfRoles: ['Administrator'] } }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
